import { Link, graphql } from 'gatsby'
import React from 'react'
import DefaultLayout from '../components/layout'
import SEO from '../components/seo'
import BuildPostListFooter from '../components/buildPostListFooter'


const BuildPostList = ({ posts,pageContext }) => {

    const tag=pageContext.tag
    const noTag=tag===''|| tag===null || tag===undefined
    return ( 
        <DefaultLayout>
        <SEO
          title={'Software Musings'}
          keywords={['Olga Kudrina', 'software architect']}
        />


    <div >
         {!noTag && (
             <div >
                  <div className="center-content"><span className="uppercase">{tag}</span></div>
                  <div className="center-content"> <Link   to="/tags" >View All Tags</Link> </div>
                      
             </div>
        )}

<div className="content-box clearfix"> 
    {
      
    posts.map( post  => {

       return (
       
        <article className="post" key={post.node.uid}>
              <Link
                alt="Post Link"
                 to={`/${post.node.uid}`}
                className="post-thumbnail"
                style={{
                  backgroundImage: `url(${post.node.data.teaser_image.localFile.childImageSharp.gatsbyImageData.images.fallback.src})`,
                }}
              />
        
          <div className="post-content">


            <h2 className="post-title">
              <Link alt="title" to={`/${post.node.uid}`}> {post.node.data.title.text}</Link>
            </h2>
            <p>{post.node.data.description.text}</p>
            <span className="post-date">
              {new Intl.DateTimeFormat('en-US',{ year: 'numeric', month: 'long'}).format(new Date(post.node.first_publication_date))} 
            </span>

          </div>
        </article>
      )
    })}
        
            
 </div>



          
</div>
<BuildPostListFooter  pageContext={pageContext} />
</DefaultLayout>
)
    }

  export default BuildPostList