import React from 'react'

import { Link } from 'gatsby'
 
const BuildPostListFooter = ({ pageContext}) => {

    const currentPage=pageContext.currentPage
    const numPages=pageContext.numPages

    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const slug=pageContext.slug
    const pPage= slug + (currentPage - 1 === 1 ? '' :(currentPage-1).toString())
    const nPage=slug+(currentPage+1).toString()
  
    
    return ( 
        <div> 
        <div className="page-number">
            {!isFirst && (
                    <Link alt="previous" o={pPage} rel="prev" className="newer-posts">
                         <span className="fa fa-arrow-left"></span>  
                    </Link>
            )} 
 
            Viewing page {currentPage} of  {numPages}

            {!isLast && (
                
                    <Link alt="next" to={nPage} rel="next" className="older-posts">
                     <span className="fa fa-arrow-right"></span> 
                    </Link>
             
                )}
                  </div>
 
     
                  <div className="page-copyright">
        {new Date().getFullYear()} &copy; Olga Kudrina
          
          </div>
          </div>
    )


}
export default BuildPostListFooter