import React from 'react'
import { graphql } from 'gatsby'

 
import BuildPostList from '../components/buildPostList'
const Tags = ({ pageContext, data }) => {

const posts = data.allPrismicPost.edges 

 
  return (
     
     <BuildPostList posts={posts}  pageContext={pageContext}/>


  )
   
  
}

export default Tags

export const pageQuery = graphql`
query tagListQuery($skip: Int!, $limit: Int!, $tag: String) {
        allPrismicPost(
              skip: $skip,
              limit: $limit
              sort: { fields: first_publication_date, order: DESC }
              filter: { tags: { in: [$tag] } } 
        )  
          {
            totalCount
            edges {
    
              node {
                  tags
                  first_publication_date
                  last_publication_date
                  data {
        
                   description {
                      html
                      text
                    }
          
                    teaser_image {
                      url
                        localFile {
                          childImageSharp {
                            gatsbyImageData(width: 600)
                          }
                        }
                      }
            
                    title {
                      html
                      text
                    }
                  }
                  tags
                  uid
         
                }
            }
          }
  }
`
